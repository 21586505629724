<script>
    import { mapActions, mapGetters, mapState } from "vuex";
    import { apiUrl } from "@/utils";
    import { metaInfo } from "@/core/MetaInfo";
    import Slider from "@main/packages/TheSlider";
    import { BLOG_BREAKPOINTS } from "@tb/settings/breakpoints";
    import Breadcrumbs from "@tb/components/Breadcrumbs";
    import BlogCard from "@tb/components/BlogCard";
    import GoodsCard from "@tb/components/ProductCard";
    import Newsletter from "@tb/components/Newsletter";

    export default {
        name: "BlogDetail",
        components: {
            Newsletter,
            Slider,
            BlogCard,
            GoodsCard,
            Breadcrumbs,
        },
        data() {
            return {
                slug: this.$route.params.slug,
                options: {
                    spaceBetween: 24,
                    ...BLOG_BREAKPOINTS,
                },
                topEl1: 0,
                topEl2: 0,
                titleBlog: "",
                descBlog: "",
            };
        },
        computed: {
            ...mapState("Blog", ["detailBlog", "otherBlog", "blogProducts", "loading"]),
            ...mapGetters("Catalog", ["mainCategories"]),
            links() {
                return {
                    items: [
                        {
                            slug: this.$route.params.slug,
                            id: "q1",
                            name: this.detailBlog.name || "",
                        },
                    ],
                };
            },
        },
        watch: {
            async $route(value) {
                let { id, products, name, previewDescription } = await this.getDetailBlog(
                    value.params.slug
                );
                this.titleBlog = name;
                this.descBlog = previewDescription;
                if (products.length > 0) {
                    await this.getProductsForBlog(products);
                }
                await this.getOtherBlog(id);
                this.calcTop();
            },
        },
        async serverPrefetch() {
            let { id, products, name, previewDescription } = await this.getDetailBlog(
                this.slug
            );
            this.titleBlog = name;
            this.descBlog = previewDescription;
            if (products.length > 0) {
                await this.getProductsForBlog(products);
            }

            return this.getOtherBlog(id);
        },
        destroyed() {
            this.clearBlog();
        },
        async mounted() {
            if (!this.detailBlog.name) {
                let { id, products, name, previewDescription } = await this.getDetailBlog(
                    this.slug
                );
                this.titleBlog = name;
                this.descBlog = previewDescription;
                if (products.length > 0) {
                    await this.getProductsForBlog(products);
                }
                await this.getOtherBlog(id);
                this.calcTop();
            } else {
                this.calcTop();
            }
            let links = [
                "https://yastatic.net/es5-shims/0.0.2/es5-shims.min.js",
                "https://yastatic.net/share2/share.js",
            ];
            let recaptchaScript;
            links.forEach((link) => {
                recaptchaScript = document.createElement("script");
                recaptchaScript.setAttribute("async", "async");
                recaptchaScript.setAttribute("defer", "defer");
                recaptchaScript.setAttribute("src", link);
                document.head.appendChild(recaptchaScript);
                recaptchaScript = null;
            });
        },
        methods: {
            ...mapActions("Blog", [
                "getDetailBlog",
                "getOtherBlog",
                "getProductsForBlog",
                "clearBlog",
            ]),
            apiUrl,
            calcTop() {
                let id1 = document.querySelector("#right-aside-product-1");
                let id2 = document.querySelector("#right-aside-product-2");
                if (id1) {
                    this.topEl1 = id1.offsetTop;
                }
                if (id2) {
                    this.topEl2 = id2.offsetTop;
                }
            },
        },
        metaInfo() {
            return metaInfo.getProductMeta({
                title: this.titleBlog,
                description: this.descBlog,
                slug: `blog/${this.slug}`,
            });
        },
    };
</script>

<template>
    <div class="l-container">
        <div class="blog-detail">
            <breadcrumbs :links="links.items" page="blog"></breadcrumbs>

            <div v-if="!loading" class="blog-detail__container">
                <div class="blog-detail__header">
                    <div class="blog-detail__title">{{ detailBlog.name }}</div>
                    <div v-if="detailBlog.previewDescription" class="blog-detail__sub-title">
                        {{ detailBlog.previewDescription }}
                    </div>

                    <div v-if="detailBlog.author" class="blog-detail__author">
                        <div class="blog-detail__author-name">{{ detailBlog.author }}</div>
                        <div class="blog-detail__author-date">
                            {{ detailBlog.createdDate }}
                        </div>
                        <div v-if="detailBlog.authorPhoto" class="blog-detail__author-ico">
                            <img :src="apiUrl(detailBlog.authorPhoto)" alt="" />
                        </div>
                    </div>

                    <div class="blog-detail__img">
                        <img :src="apiUrl(detailBlog.detailImage)" alt="" />
                    </div>
                </div>

                <div class="blog-detail__body">
                    <div v-html="detailBlog.description" class="blog-detail__html"></div>

                    <div class="blog-detail__footer">
                        <div class="blog-detail__share">
                            <div class="blog-detail__share-text">Поделиться</div>
                            <div
                                class="ya-share2"
                                data-services="vkontakte,facebook,twitter,telegram"
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="blog-detail__loading">
                <preloader :show="loading" lg-size is-catalog></preloader>
            </div>

            <div v-if="blogProducts.length > 0" class="blog-detail__products">
                <div
                    v-show="topEl1 !== 0"
                    class="blog-detail__product"
                    :style="{ top: `${topEl1}px` }"
                >
                    <goods-card
                        :goods="blogProducts[0]"
                        :key="blogProducts[0].id"
                    ></goods-card>
                </div>

                <div
                    v-if="blogProducts.length === 2"
                    v-show="topEl2 !== 0"
                    class="blog-detail__product"
                    :style="{ top: `${topEl2}px` }"
                >
                    <goods-card
                        :goods="blogProducts[1]"
                        :key="blogProducts[1].id"
                    ></goods-card>
                </div>
            </div>

            <div v-if="otherBlog.length > 0" class="blog-detail__other">
                <div class="blog-detail__other-title">Что еще почитать?</div>
                <slider
                    nameSlider="otherBlog"
                    :is-pagination="true"
                    :is-arrow="false"
                    :options="options"
                    :list="otherBlog"
                >
                    <blog-card
                        slot="swiper-slide"
                        class="swiper-slide"
                        :blog="item"
                        v-for="item in otherBlog"
                        :key="item.id"
                    ></blog-card>
                </slider>
            </div>

            <div class="blog-detail__newsletter">
                <newsletter></newsletter>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .blog-detail {
        position: relative;
    }

    .blog-detail__header {
        max-width: 724px;
        margin: 0 auto;
    }

    .blog-detail__loading {
        min-height: 550px;
    }

    .blog-detail__body {
        max-width: 724px;
        margin: 0 auto;

        @include bp($bp-desktop-sm) {
            width: calc(100% - 300px);
            margin-left: 0;
        }

        @include bp($bp-desktop-md) {
            max-width: 724px;
            margin-left: auto;
        }
    }

    .blog-detail__title {
        font-weight: 600;
        font-size: 30px;
        line-height: 34px;
        margin-bottom: 8px;
        text-align: center;

        @include bp($bp-desktop-sm) {
            font-size: 40px;
            line-height: 44px;
        }
    }

    .blog-detail__sub-title {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 24px;
        text-align: center;

        @include bp($bp-desktop-sm) {
            font-size: 18px;
            line-height: 32px;
            margin-bottom: 32px;
        }
    }

    .blog-detail__author {
        text-align: center;
    }

    .blog-detail__author-name {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 4px;
    }

    .blog-detail__author-date {
        font-size: 12px;
        line-height: 16px;
    }

    .blog-detail__author-ico {
        text-align: center;
        position: relative;
        @include box(124px);
        background-color: #fff;
        box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.12);
        border-radius: 50%;
        margin: 16px auto -62px;

        img {
            @include center-absolute(0);
            margin: auto;
            @include box(100px);
            object-fit: cover;
            border-radius: 50%;
        }
    }

    .blog-detail__img {
        margin-bottom: 32px;
    }

    .blog-detail__html {
        b {
            font-weight: 600;
        }

        a {
            text-decoration: underline;
            color: var(--primary);
        }

        h2 {
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 16px;

            @include bp($bp-desktop-sm) {
                font-size: 32px;
                line-height: 40px;
            }
        }

        h3 {
            font-weight: 600;
            font-size: 17px;
            line-height: 20px;
            margin-bottom: 16px;

            @include bp($bp-desktop-sm) {
                font-size: 24px;
                line-height: 30px;
            }
        }

        h4 {
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            margin-bottom: 8px;

            @include bp($bp-desktop-sm) {
                font-size: 16px;
                line-height: 24px;
            }
        }

        p {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 24px;

            strong {
                font-weight: 600;
                font-size: 18px;
                line-height: 24px;
                color: var(--secondary-text);
                margin-bottom: 24px;

                @include bp($bp-desktop-sm) {
                    font-size: 24px;
                    line-height: 32px;
                    margin-bottom: 32px;
                }
            }
        }

        img {
            margin-bottom: 24px;

            @include bp($bp-desktop-sm) {
                margin-bottom: 32px;
            }
        }

        blockquote {
            display: block;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: var(--primary);
            text-align: center;
            margin-bottom: 24px;
            background-color: var(--neutral--light);
            padding: 16px;

            @include bp($bp-desktop-sm) {
                font-size: 22px;
                line-height: 32px;
                padding: 32px;
                margin-bottom: 32px;
            }

            @include bp($bp-desktop-md) {
                padding: 32px 112px;
            }
        }

        ul {
            list-style: none;
            margin-bottom: 32px;
            padding-left: 0;

            li {
                position: relative;
                padding-left: 24px;
                font-size: 16px;
                line-height: 24px;

                &:after {
                    content: "";
                    position: absolute;
                    top: 9px;
                    left: 9px;
                    @include box(6px);
                    background-color: var(--primary-text);
                    border-radius: 50%;
                }

                &:not(:first-child) {
                    margin-top: 12px;
                }
            }
        }

        ol {
            list-style: none;
            counter-reset: myCounter;
            padding-left: 0;
            margin-bottom: 32px;

            li {
                position: relative;
                padding-left: 24px;
                font-size: 16px;
                line-height: 24px;

                &:after {
                    display: none;
                }

                &:before {
                    counter-increment: myCounter;
                    content: counter(myCounter) ".";
                    position: absolute;
                    top: 8px;
                    left: 0;
                    display: block;
                    font-size: 15px;
                    font-weight: bold;
                    line-height: 1;
                }

                &:not(:first-child) {
                    margin-top: 12px;
                }
            }
        }
    }

    .blog-detail__footer {
        border-top: 1px solid var(--primary-border--light);
        padding-top: 32px;
        margin-bottom: 160px;
    }

    .blog-detail__share {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .blog-detail__share-text {
        font-size: 12px;
        line-height: 16px;
        color: var(--secondary-text);
        margin-right: 12px;
    }

    .blog-detail__newsletter {
        margin-bottom: 56px;

        @include bp($bp-desktop-sm) {
            margin-bottom: 96px;
        }
    }

    .blog-detail__other {
        margin-bottom: 24px;
    }

    .blog-detail__other-title {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 16px;
        font-weight: 600;
        text-align: center;

        @include bp($bp-desktop-sm) {
            font-size: 32px;
            line-height: 40px;
            margin-bottom: 32px;
        }
    }

    .blog-detail__products {
        display: flex;
        justify-content: center;
        margin: -110px 0 56px;

        @include bp($bp-desktop-sm) {
        }

        .blog-detail__product {
            width: 50%;

            @include bp($bp-tablet-sm) {
                width: calc(50% - 12px);
                margin: 0 12px;
            }

            @include bp($bp-desktop-sm) {
                position: absolute;
                right: 0;
                width: 234px;
                margin: -130px 0 0;
            }
        }

        .goods-card {
            width: 100%;
        }
    }
</style>
