/**
 * Бренды
 */
export const BRANDS_BREAKPOINT = {
    breakpoints: {
        320: {
            spaceBetween: 16,
            slidesPerView: 2,
        },
        768: {
            spaceBetween: 16,
            slidesPerView: 3,
        },
        992: {
            spaceBetween: 16,
            slidesPerView: 4,
        },
    },
};

/**
 * Медиа
 */
export const MEDIA_BREAKPOINT = {
    spaceBetween: 16,
    breakpoints: {
        320: {
            slidesPerView: "auto",
        },
        479: {
            slidesPerView: 2,
        },
        768: {
            slidesPerView: 3,
        },
        1280: {
            slidesPerView: 4,
        },
    },
};

/**
 * Отзывы
 */
export const REVIEWS_BREAKPOINTS = {
    breakpoints: {
        320: {
            slidesPerView: "auto",
        },
        600: {
            slidesPerView: 2,
            spaceBetween: 16,
        },
        768: {
            slidesPerView: 3,
            spaceBetween: 16,
        },
        992: {
            slidesPerView: 4,
            spaceBetween: 24,
        },
    },
};

/**
 * Товары
 */
export const GOODS_BREAKPOINTS = {
    breakpoints: {
        320: {
            slidesPerView: 2,
            spaceBetween: 24,
        },
        680: {
            spaceBetween: 24,
            slidesPerView: 4,
        },
        1025: {
            spaceBetween: 0,
            slidesPerView: 4,
        },
    },
};

/**
 * Товары
 */
export const GOODS_PRODUCT_BREAKPOINTS = {
    breakpoints: {
        320: {
            slidesPerView: 2,
            spaceBetween: 24,
        },
        680: {
            spaceBetween: 24,
            slidesPerView: 3,
        },
        1025: {
            slidesPerView: 3,
            spaceBetween: 0,
        },
    },
};

/**
 * Товары
 */
export const EOL_BREAKPOINTS = {
    breakpoints: {
        320: {
            slidesPerView: 2,
            spaceBetween: 24,
        },
        1025: {
            slidesPerView: 2,
            spaceBetween: 0,
        },
    },
};

export const BLOG_BREAKPOINTS = {
    breakpoints: {
        320: {
            slidesPerView: 1,
            spaceBetween: 8,
        },
        480: {
            slidesPerView: 2,
            spaceBetween: 24,
        },
        680: {
            spaceBetween: 24,
            slidesPerView: 3,
        },
        1025: {
            slidesPerView: 4,
            spaceBetween: 24,
        },
    },
};

export const UPSALE_BREAKPOINTS = {
    breakpoints: {
        320: {
            slidesPerView: 2,
            spaceBetween: 0,
        },
        680: {
            spaceBetween: 0,
            slidesPerView: 3,
        },
    },
};
